import { useState, useEffect } from "react"

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(
        JSON.parse(localStorage.getItem("notes")) || initialValue
    )

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}

export default useLocalStorage
